import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import { useCurrentUser } from 'shared/data'
import { ModuleRoute } from 'shared/types'

export const useCurrentLayoutSettings = (routes: ModuleRoute[]) => {
  const { pathname } = useLocation<{ background?: Location } | null>()
  const currentRoute = routes.find(routeProps =>
    matchPath(pathname, routeProps)
  )
  return currentRoute?.layoutSettings || {}
}

const filterRoutes = (
  routes: ModuleRoute[],
  options: { isAdmin: boolean; isOrganizationAdmin: boolean }
): ModuleRoute[] => {
  return routes.filter(r => {
    if (r.organizationAdmin) {
      if (options.isOrganizationAdmin) return true
      return false
    }
    if (r.admin) {
      if (options.isAdmin) return true
      return false
    }
    return true
  })
}

export const useAvailableRoutes = (routes: ModuleRoute[]) => {
  const { data, loading } = useCurrentUser()

  const nonAdminRoutes = filterRoutes(routes, {
    isAdmin: false,
    isOrganizationAdmin: false,
  })

  const availableRoutes = useMemo(() => {
    if (data) {
      const isAdmin = data.viewer.isAdmin
      const organizationsCount = data.viewer.organizations?.totalCount || 0
      const isOrganizationAdmin = organizationsCount > 0
      return filterRoutes(routes, { isAdmin, isOrganizationAdmin })
    }
    return nonAdminRoutes
  }, [data, routes, nonAdminRoutes])

  return { availableRoutes, loading }
}
