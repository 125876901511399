import { gql } from '@apollo/client'

export const GET_CURRENT_USER = gql`
  query CurrentUserQuery {
    viewer {
      isAdmin,
      name,
      id,
      email,
      viewerPreferences {
        marketingConsentAccepted
      },
      organizations {
        totalCount
      }
    }
  }
`