import { Redirect, Route, Switch, useLocation } from 'react-router'
import { SvgIconComponent } from '@mui/icons-material'
import { paths } from 'config'
import { ModuleRoute } from 'shared/types'
import { useDialog, useIsSmaller } from 'shared/hooks'
import PrivateRoute from '../PrivateRoute'
import Topbar from './Topbar'
import Drawer from './Drawer'
import { useCurrentLayoutSettings, useAvailableRoutes } from './Layout.utils'
import { Container, Content } from './Layout.style'

export interface LayoutNavItem {
  path: string
  label: string
  icon?: SvgIconComponent
}

export interface LayoutProps {
  routes: ModuleRoute[]
  hideDrawer?: boolean
  hideTopbar?: boolean
}

const Layout = ({ routes }: LayoutProps) => {
  const { search } = useLocation()
  const {
    drawerProps = {},
    topbarProps = {},
    contentProps = {},
  } = useCurrentLayoutSettings(routes)
  const isSmaller = useIsSmaller('xl')
  const [drawerOpen, toggleDrawerOpen] = useDialog()

  const { availableRoutes } = useAvailableRoutes(routes)

  const getNavItems = (availableRoutes: ModuleRoute[]) =>
    availableRoutes.filter(
      ({ linkSettings: { label, icon } = {} }) => label || icon
    )

  return (
    <Container>
      <Drawer
        navItems={getNavItems(availableRoutes)}
        {...drawerProps}
        hidden={drawerProps.hidden}
        open={drawerOpen}
        onClose={toggleDrawerOpen}
        variant={isSmaller ? 'temporary' : 'permanent'}
      />
      <Content
        shrink={isSmaller ? false : !drawerProps.hidden}
        {...contentProps}
      >
        <Topbar {...topbarProps} onMenuClick={toggleDrawerOpen} />
        <Switch>
          {availableRoutes
            .reduce(
              (prev, { subroutes, ...route }) =>
                subroutes ? [...prev, route, ...subroutes] : [...prev, route],
              [] as ModuleRoute[]
            )
            .map((route, i) => (
              <PrivateRoute key={`route-${i}`} {...route} />
            ))}
          <Route
            component={() => <Redirect to={`${paths.signIn}${search}`} />}
          />
        </Switch>
      </Content>
    </Container>
  )
}

export default Layout
