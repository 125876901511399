import { PropsWithChildren } from "react";
import { useCurrentUser } from "shared/data";
import { AuthContextValue, useAuthContext } from "./AuthContext";
import { LoadingStatus } from "shared/types";
import PageLoader from "./PageLoader";

interface AuthLoaderProps {
    user: AuthContextValue;
}

let firstLoad: boolean = true

export const useAuthLoader = () => {
    const { loading: cloudUserLoading } = useCurrentUser()
    const { loading: loadingStatus } = useAuthContext()
    const firebaseUserLoading = [LoadingStatus.Idle, LoadingStatus.Pending].includes(loadingStatus);

    const loading = cloudUserLoading || firebaseUserLoading
    if (!loading && firstLoad)
        firstLoad = false

    return {
        loading,
        firstLoad
    }
}

const AuthLoader = ({
    children
}: PropsWithChildren<AuthLoaderProps>) => {
    const { loading, firstLoad } = useAuthLoader()
    return (
        <>
            {!firstLoad || !loading ? <>{children}</> : <PageLoader progressColor="secondary" fullHeight={true} />}
        </>
    )
}
export default AuthLoader
